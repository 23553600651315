import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';


const priceRegex = /[.,](\d)(?!\d)/;

/* Standardized EUR price component with option to drop fraction if it is zero.
 * Ensures fraction is two-digit if present (avoiding e.g. "€143.5").
 */
const FormattedPrice = ({ fractionOptional, value }) => (
  <FormattedNumber
    currency="EUR"
    currencyDisplay="symbol"
    minimumFractionDigits={fractionOptional ? 0 : 2}
    style="currency" // eslint-disable-line react/style-prop-object
    value={value}
  >
    {(text) => (<span>{text.replace(priceRegex, '$&0')}</span>)}
  </FormattedNumber>
);

FormattedPrice.propTypes = {
  fractionOptional: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default FormattedPrice;
