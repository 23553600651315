import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { fontStyles } from '../constants/styles';
import { breakpoints } from '../constants/mediaqueries';
import { SPACING_VERTICAL } from '../constants/spacings';

import FormattedPrice from './FormattedPrice';
import LocalisedLink from './LocalisedLink';


export const Room = styled.li`
  ${SPACING_VERTICAL.s}
  position: relative;

  > a {
    display: block;
  }

  @media ${breakpoints.l} {
    width: calc(50% - 4vw);
    margin-top: 0;
    ${SPACING_VERTICAL.l}
  }
`;

const Overlay = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: .625rem 1.25rem;
  ${fontStyles.bodySmall}
`;

const RoomDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1.25rem;
  ${fontStyles.body}

  a {
    ${fontStyles.body}
  }

  a > span:first-of-type,
  a > span:nth-of-type(3) {
    ${fontStyles.bodySmall}
  }
`;

const RoomTeaser = ({ disabled, room, roomPath, stays }) => (
  <Room>
    <If condition={disabled}>
      <Overlay><FormattedMessage id="booking.resultsRoomUnavailable" /></Overlay>
    </If>
    <LocalisedLink hasUnderline={false} to={roomPath}>
      <Img key={room.featuredImage.id} fluid={room.featuredImage.fluid} style={{ opacity: disabled ? 0.25 : 1 }} />
    </LocalisedLink>
    <RoomDescription>
      <LocalisedLink hasUnderline={false} to={roomPath}>{room.name}</LocalisedLink>
      <LocalisedLink to={roomPath}>
        <Choose>
          <When condition={stays}>
            <FormattedMessage defaultMessage="From" id="booking.resultsRoomRate" />
            &nbsp;
            <FormattedPrice
              fractionOptional
              value={Math.min(...Object.values(stays).map((s) => parseFloat(s.total) / s.duration))}
            />&nbsp;/&nbsp;
            <FormattedMessage defaultMessage="night" id="booking.perNight" />
          </When>
          <Otherwise>
            <FormattedMessage defaultMessage="View Room" id="booking.roomsViewRoom" />
          </Otherwise>
        </Choose>
      </LocalisedLink>
    </RoomDescription>
  </Room>
);


RoomTeaser.propTypes = {
  disabled: PropTypes.bool,
  room: PropTypes.object.isRequired,
  roomPath: PropTypes.string.isRequired,
  stays: PropTypes.object,
};

export default RoomTeaser;
