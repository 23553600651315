import React from 'react';
import PropTypes from 'prop-types';


const PlusSymbol = ({ color, width }) => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <line stroke={color} strokeWidth={width} x1="0" x2="32" y1="16" y2="16" />
    <line stroke={color} strokeWidth={width} x1="16" x2="16" y1="0" y2="32" />
  </svg>
);

PlusSymbol.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
};

PlusSymbol.defaultProps = {
  color: 'black',
  width: '1px',
};

export default PlusSymbol;
