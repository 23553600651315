import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { connect } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { breakpoints } from '../constants/mediaqueries';
import { fontStyles, underlineStyles } from '../constants/styles';

import BookingWidget from '../components/BookingWidget';
import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import RoomTeaser from '../components/RoomTeaser';


export const RoomList = styled.ul`
  opacity: ${(props) => (props.dimmed ? 0.25 : 1)};
  transition: opacity 300ms ease-out;

  @media ${breakpoints.l} {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }
`;

const SearchResults = styled.p`
  margin-bottom: 2em;
  ${fontStyles.bodySmall}

  a {
    ${underlineStyles}
  }
`;


const RoomsPage = ({ data, location, pageContext, searchInitiated, searchInProgress, stays }) => {
  const { languageSwitch } = pageContext;

  const footerData = data.allDatoCmsFooter.edges[0].node;
  const roomsPage = data.allDatoCmsRoomsPage.edges[0].node;
  const { gradient, name, rooms, seoMetaTags, slug } = roomsPage;
  const colorObject = gradient[0].color;

  const availableRoomTypes = Object.keys(stays);

  const availableRooms = rooms
    .filter((r) => availableRoomTypes.includes(r.bookingId))
    .map((r) => {
      r.minPrice = Math.min(...Object.values(stays[r.bookingId]).map((s) => parseFloat(s.total)));
      return r;
    });
  availableRooms.sort((a, b) => a.minPrice - b.minPrice);

  // We can retain the original order of the unavailable rooms
  const unavailableRooms = rooms
    .filter((r) => !availableRoomTypes.includes(r.bookingId));
  const sortedRooms = availableRooms.concat(unavailableRooms);

  return (
    <Layout hideBookingLink languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{name}</PageTitle>
        <BookingWidget buttonColor={colorObject.hex} colorObject={colorObject} />
        <Choose>
          <When condition={searchInProgress}>
            <SearchResults>
              <FormattedMessage defaultMessage="Searching..." id="booking.roomsSearching" />
            </SearchResults>
          </When>
          <When condition={availableRoomTypes.length || searchInitiated}>
            <With numRoomsAvailable={availableRoomTypes.length ? availableRoomTypes.length : 0}>
              <SearchResults>
                <FormattedHTMLMessage
                  id="booking.resultsSummary"
                  values={{ numRoomsAvailable }}
                />
              </SearchResults>
            </With>
          </When>
        </Choose>
        <RoomList dimmed={searchInProgress}>
          <For each="room" of={sortedRooms}>
            <RoomTeaser
              key={`room_${room.id}`}
              disabled={!!Object.keys(stays).length && !stays[room.bookingId]}
              room={room}
              roomPath={`/${slug}/${room.slug}`}
              stays={stays[room.bookingId]}
            />
          </For>
        </RoomList>
      </GradientWrapper>
    </Layout>
  );
};

RoomsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  searchInitiated: PropTypes.bool.isRequired,
  searchInProgress: PropTypes.bool.isRequired,
  stays: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { searchInitiated, searchInProgress, stays } = state.availability;
  return {
    searchInitiated,
    searchInProgress,
    stays,
  };
};

export default connect(mapStateToProps)(RoomsPage);

export const query = graphql`
  query RoomsPage($locale: String!) {
    allDatoCmsRoomsPage(filter:{ locale: { eq: $locale } }) {
      edges {
        node {
          gradient {
            color {
              hex
            }
          }
          slug
          name
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          rooms {
            id
            slug
            name
            bookingId
            featuredImage {
              fluid(maxWidth: 2400, maxHeight: 1600, imgixParams: { fm:"jpg", auto:"format" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;
