import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import { COLOR_BLACK, COLOR_GRAY_BG } from '../constants/colors';
import { buttonStyles, fontStyles } from '../constants/styles';
import { getError, getRetryAction } from '../state/reducers';
import { customMarkdown } from '../helpers/markdown';


export const Error = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  padding: 1rem;
  margin: 2rem 0;
  border: 1px solid ${(props) => props.bgColor};
  background ${(props) => props.bgColor};
  color: ${(props) => props.color};
  ${fontStyles.bodySmall}
`;

const StyledButton = styled.button`
  ${buttonStyles}
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
`;

const ErrorMessage = ({ bgColor = COLOR_GRAY_BG, color = COLOR_BLACK, error, retryAction, retry, intl: { formatMessage } }) => (
  <If condition={error}>
    <Error bgColor={bgColor} color={color}>
      <Choose>
        <When condition={error.key}>
          {customMarkdown(formatMessage({ id: error.key }))}
        </When>
        <Otherwise>
          <p>{error.message}</p>
        </Otherwise>
      </Choose>
      <If condition={retryAction}>
        <StyledButton bgColor={color} color={bgColor} onClick={() => retry(retryAction)} type="button"><FormattedMessage id="global.retry" /></StyledButton>
      </If>
    </Error>
  </If>
);

ErrorMessage.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.object,
  retryAction: PropTypes.string,
  retry: PropTypes.func,
  intl: intlShape,
};

const mapStateToProps = (state) => ({
  error: getError(state),
  retryAction: getRetryAction(state),
});

const mapDispatchToProps = (dispatch) => ({
  retry: (retryAction) => dispatch({ type: retryAction }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ErrorMessage));
