import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import {
  FormattedDate, FormattedHTMLMessage, FormattedMessage, FormattedNumber, injectIntl, intlShape,
} from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  DayPickerRangeController, isInclusivelyAfterDay, isInclusivelyBeforeDay, isSameDay,
} from 'react-dates';
import { START_DATE } from 'react-dates/constants';
import 'react-dates/lib/css/_datepicker.css';

import { COLOR_BLACK, COLOR_WHITE } from '../constants/colors';
import { breakpoints } from '../constants/mediaqueries';
import {
  buttonStyles, fontStyles, underlineStyles, underlinedButtonStyles,
} from '../constants/styles';
import { handleNumberInput, parseToNumber } from '../helpers/numbers';
import { isTinyViewport, isXSmallViewport } from '../helpers/window';
import {
  BOOKING_WIDGET_CLEAR_DATES,
  BOOKING_WIDGET_UPDATE_PARAMS,
  BOOKING_WIDGET_UPDATE_PROMO_CODE,
  SEARCH_PARAMS_UPDATED,
} from '../state/actionTypes';
import { initialState } from '../state/reducers/BookingWidget';
import { windowSizeTypes } from '../types';

import ErrorMessage from './ErrorMessage';
import InvisibleButton from './InvisibleButton';
import PlusSymbol from './PlusSymbol';
import MinusSymbol from './MinusSymbol';
import StyledFieldset from './StyledFieldset';


const DATES_BUTTON = 'dates';
const GUESTS_BUTTON = 'guests';

const FlexContainer = styled.div`
  display: flex;
`;

const StyledContainer = styled(FlexContainer)`
  padding: 1em 0;
  ${fontStyles.bodySmall}
`;

const StyledWrapper = styled(StyledContainer)`
  align-items: center;
  border-top: 1px solid ${COLOR_BLACK};
  border-bottom: 1px solid ${COLOR_BLACK};
`;

const StyledPromoCodeWrapper = styled(StyledContainer)`
  justify-content: flex-end;
  margin-bottom: 2em;
`;

const StyledLabel = styled.p`
  display: none;

  @media ${breakpoints.xs} {
    display: block;
    margin-right: 1em;
  }
`;

const StyledControlsWrapper = styled(FlexContainer)`
  flex-direction: column;
  vertical-align: middle;


  & + & {
    margin-left: 1em;
  }

  @media ${breakpoints.l} {
    position: relative;
  }
`;

const StyledButton = styled.button`
  ${buttonStyles}
  background-color: ${(props) => (props.isActive ? COLOR_BLACK : 'rgba(255, 255, 255, 0)')};
  color: ${(props) => (props.isActive ? props.color : COLOR_BLACK)};
  cursor: pointer;
`;

const StyledWidget = styled.div`
  position: fixed;
  top: 4em;
  z-index: 1;
  width: ${({ isDatePicker }) => (isDatePicker ? '335px' : 'calc(100% - 40px)')};
  left: 0;
  right: 0;
  margin: auto;

  display: block;
  padding: 1em;

  border-radius: .5em;
  background-color: ${COLOR_BLACK};
  color: ${(props) => props.color};
  ${fontStyles.bodySmall}

  @media (min-width: 673px) {
    position: absolute;
    width: ${({ isDatePicker }) => (isDatePicker ? '635px' : '260px')};
  }

  > label {
    display: flex;
    justify-content: space-between;
  }
`;

const ButtonContainer = styled(FlexContainer)`
  justify-content: space-between;
  margin-top: 1rem;
`;

const UnderlinedButton = styled.button`
  ${fontStyles.bodySmall}
  ${underlinedButtonStyles}
  color: ${(props) => props.color};

  &::before {
    background-color: ${(props) => props.color};
  }
`;

const MoreMessaging = styled.p`
  margin: 1em 0;

  a {
    display: inline-block;
    ${underlineStyles}
  }

  a::before {
    ${({ color }) => `background-color: ${color};`}
  }
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;

  > label {
    display: flex;
    align-items: center;
  }
`;

const PromoCodeInput = styled.input`
  margin-left: .25rem;
  padding: .5em;

  border: 1px solid ${COLOR_BLACK};
  background-color: transparent;

  ${fontStyles.bodySmall}
`;

const PromoCodeSubmitButton = styled.button`
  display: flex;
  width: 2.8em;
  height: 2.8em;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 50%;

  background-color: ${(props) => (props.edited ? COLOR_BLACK : 'rgba(0,0,0,0)')};
  border: 1px solid ${COLOR_BLACK};

  ${fontStyles.bodySmall}
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const PromoCodeButton = styled(InvisibleButton)`
  ${fontStyles.bodySmall}
`;

class BookingWidget extends Component {
  constructor(props) {
    super(props);

    const { intl: { locale } } = this.props;
    moment.locale(locale);

    const {
      arrivalDate, departureDate, numAdults, numChildren, promoCode,
    } = this.props;

    this.state = {
      arrivalDate: arrivalDate ? moment(arrivalDate) : null,
      buttonsInitialized: new Set(),
      departureDate: departureDate ? moment(departureDate) : null,
      focusedInput: START_DATE,
      numAdults,
      numChildren,
      openButton: null,
      promoCode,
      promoCodeEditing: false,
      promoCodeVisible: !!promoCode,
    };

    this.datePicker = React.createRef();
    this.guestPicker = React.createRef();

    this.applyParams = this.applyParams.bind(this);
    this.applyPromoCode = this.applyPromoCode.bind(this);
    this.clearDates = this.clearDates.bind(this);
    this.clearGuests = this.clearGuests.bind(this);
    this.clearPromoCode = this.clearPromoCode.bind(this);
    this.createNumberHandler = this.createNumberHandler.bind(this);
    this.handlePromoCode = this.handlePromoCode.bind(this);
    this.showPromoCodeField = this.showPromoCodeField.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.incrementStateKey = this.incrementStateKey.bind(this);
    this.decrementStateKey = this.decrementStateKey.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.clearOpenButtons = this.clearOpenButtons.bind(this);
  }

  componentDidMount() {
    const { intl: { locale }, searchInitiated, searchParamsUpdated } = this.props;
    if (searchInitiated) {
      searchParamsUpdated({ locale });
    }
    document.body.addEventListener('keyup', this.handleKeyUp, false);
    document.body.addEventListener('mousedown', this.handleMouseDown, false);
  }

  componentDidUpdate(prevProps) {
    const {
      arrivalDate, departureDate, intl: { locale }, numAdults, numChildren,
      promoCode, searchParamsUpdated,
    } = this.props;
    if (locale !== prevProps.intl.locale) {
      moment.locale(locale);
    }
    if (
      ((arrivalDate !== prevProps.arrivalDate)
        || (departureDate !== prevProps.departureDate)
        || (locale !== prevProps.intl.locale)
        || (numAdults !== prevProps.numAdults)
        || (numChildren !== prevProps.numChildren)
        || (promoCode !== prevProps.promoCode))
      && arrivalDate != null
      && departureDate != null
      && numAdults >= 1
    ) {
      searchParamsUpdated({ locale });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('keyup', this.handleKeyUp);
    document.body.removeEventListener('mousedown', this.handleMouseDown);
  }

  handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.clearOpenButtons();
    }
  }

  handleMouseDown(event) {
    const isDatePickerTarget = this.datePicker && this.datePicker.current && this.datePicker.current.contains(event.target);
    const isGuestPickerTarget = this.guestPicker && this.guestPicker.current && this.guestPicker.current.contains(event.target);
    if (isDatePickerTarget || isGuestPickerTarget) {
      return;
    }
    const { openButton } = this.state;
    if (openButton) {
      this.applyParams();
    }
  }

  clearOpenButtons() {
    this.setState({ openButton: null });
  }

  incrementStateKey(key) {
    this.setState((prevState) => {
      const prevValue = parseToNumber(prevState[key]);
      return { [key]: prevValue + 1 };
    });
  }

  decrementStateKey(key) {
    this.setState((prevState) => {
      const prevValue = parseToNumber(prevState[key]);
      return { [key]: prevValue - 1 };
    });
  }

  createNumberHandler(key) {
    return ({ target }) => {
      const newValue = handleNumberInput(target);

      if (typeof (newValue) !== 'undefined') {
        this.setState({
          [key]: newValue,
        });
      }
    };
  }

  handlePromoCode({ target: { value } }) {
    this.setState({ promoCode: value });
  }

  applyParams() {
    const { arrivalDate, departureDate } = this.state;
    if (!departureDate) {
      this.clearOpenButtons();
      return;
    }

    const numAdults = this.state.numAdults === '' ? 1 : this.state.numAdults;
    const numChildren = this.state.numChildren === '' ? 0 : this.state.numChildren;

    const params = {
      arrivalDate: arrivalDate.format('YYYY-MM-DD'),
      departureDate: departureDate.format('YYYY-MM-DD'),
      numAdults,
      numChildren,
    };

    const dispatchUpdate = () => this.props.updateParams(params);
    if (numAdults !== this.state.numAdults || numChildren !== this.state.numChildren) {
      this.setState({ numAdults, numChildren }, dispatchUpdate);
    } else {
      dispatchUpdate();
    }
    this.clearOpenButtons();
  }

  applyPromoCode(e) {
    e.preventDefault();
    const { promoCode } = this.state;
    this.setState({ promoCodeEditing: false });
    this.props.updatePromoCode({
      promoCode,
    });
  }

  clearDates() {
    this.setState({
      arrivalDate: null,
      departureDate: null,
      openButton: null,
      focusedInput: START_DATE,
    });
    this.props.clearDates();
  }

  clearGuests() {
    const { numAdults, numChildren } = initialState;
    this.setState({
      numAdults,
      numChildren,
      openButton: null,
    });
  }

  clearPromoCode() {
    const promoCode = '';
    this.setState({
      promoCode,
    });
    this.props.updatePromoCode({
      promoCode,
    });
  }

  showPromoCodeField() {
    this.setState({
      promoCodeVisible: true,
    });
  }

  toggleButton(event) {
    const currentButton = event.currentTarget.id;

    this.setState((prevState) => ({
      openButton: prevState.openButton === currentButton ? null : currentButton,
      buttonsInitialized: prevState.buttonsInitialized.add(currentButton),
    }));
  }

  render() {
    const {
      arrivalDate, buttonsInitialized, departureDate, focusedInput, numAdults,
      numChildren, openButton, promoCode, promoCodeEditing, promoCodeVisible,
    } = this.state;
    const {
      blockedDays, buttonColor, intl: { messages },
      promoCodeValid, searchInitiated, searchInProgress, windowSize,
    } = this.props;

    const numGuests = numAdults + numChildren || 0;
    const maxGuests = parseInt(messages['booking.guestsMax'], 10);

    const smallDevice = isTinyViewport(windowSize) || isXSmallViewport(windowSize);

    const yesterday = moment().subtract(1, 'days');

    return (
      <Fragment>
        <ErrorMessage bgColor={COLOR_BLACK} color={buttonColor} />
        <StyledWrapper>
          <StyledLabel>
            <FormattedMessage defaultMessage="Your Stay" id="booking.searchLabel" />
          </StyledLabel>
          <StyledControlsWrapper innerRef={this.datePicker}>
            <StyledButton color={buttonColor} id={DATES_BUTTON} isActive={arrivalDate && departureDate} onClick={this.toggleButton}>
              <Choose>
                <When condition={arrivalDate && departureDate}>
                  <FormattedDate day="numeric" month="short" value={moment(arrivalDate)} /> –&nbsp;
                  <FormattedDate day="numeric" month="short" value={moment(departureDate)} />
                </When>
                <Otherwise>
                  <FormattedMessage id="booking.dates" />
                </Otherwise>
              </Choose>
            </StyledButton>
            <If condition={openButton === DATES_BUTTON}>
              <StyledWidget color={buttonColor} isDatePicker>
                <DayPickerRangeController
                  endDate={departureDate}
                  focusedInput={focusedInput}
                  isDayBlocked={(day1) => (focusedInput === 'startDate' && blockedDays.some((day2) => isSameDay(day1, day2)))}
                  isOutsideRange={(day) => isInclusivelyBeforeDay(day, yesterday) || isInclusivelyAfterDay(day, moment().add(1, 'year'))}
                  numberOfMonths={smallDevice ? 1 : 2}
                  onDatesChange={({ startDate, endDate }) => this.setState({ arrivalDate: startDate, departureDate: endDate })}
                  onFocusChange={(input) => this.setState({ focusedInput: !input ? START_DATE : input })}
                  startDate={arrivalDate}
                />
                <ButtonContainer>
                  <UnderlinedButton color="#f85365" onClick={this.clearDates} type="button"><FormattedMessage id="booking.inputClear" /></UnderlinedButton>
                  <UnderlinedButton color="#f85365" onClick={this.applyParams} type="button"><FormattedMessage id="booking.inputApply" /></UnderlinedButton>
                </ButtonContainer>
              </StyledWidget>
            </If>
          </StyledControlsWrapper>

          <StyledControlsWrapper innerRef={this.guestPicker}>
            <StyledButton color={buttonColor} id={GUESTS_BUTTON} isActive={searchInitiated || buttonsInitialized.has(GUESTS_BUTTON)} onClick={this.toggleButton}>
              <Choose>
                <When condition={searchInitiated || buttonsInitialized.has(GUESTS_BUTTON)}>
                  <FormattedMessage
                    id="booking.guestsWithNumber"
                    values={{
                      formattedNumGuests: (<FormattedNumber value={numGuests} />),
                      numGuests,
                    }}
                  />
                </When>
                <Otherwise>
                  <FormattedMessage id="booking.guests" />
                </Otherwise>
              </Choose>
            </StyledButton>
            <If condition={openButton === GUESTS_BUTTON}>
              <StyledWidget color={buttonColor}>
                <label htmlFor="numAdults">{/* eslint-disable-line */}
                  <FormattedMessage id="booking.guestsAdults" />
                  <StyledFieldset color={buttonColor}>
                    <button
                      disabled={numAdults <= 1}
                      onClick={() => this.decrementStateKey('numAdults')}
                      type="button"
                    >
                      <MinusSymbol color={buttonColor} width="2px" />
                    </button>
                    <button
                      disabled={numAdults >= (maxGuests - numChildren)}
                      onClick={() => this.incrementStateKey('numAdults')}
                      type="button"
                    >
                      <PlusSymbol color={buttonColor} width="2px" />
                    </button>
                    <input
                      id="numAdults"
                      max={maxGuests - numChildren}
                      min="1"
                      onChange={this.createNumberHandler('numAdults')}
                      type="number"
                      value={numAdults}
                    />
                  </StyledFieldset>
                </label>
                <label htmlFor="numChildren">{/* eslint-disable-line */}
                  <FormattedMessage id="booking.guestsChildren" />
                  <StyledFieldset color={buttonColor}>
                    <button
                      disabled={numChildren <= 0}
                      onClick={() => this.decrementStateKey('numChildren')}
                      type="button"
                    >
                      <MinusSymbol color={buttonColor} width="2px" />
                    </button>
                    <button
                      disabled={numChildren >= (maxGuests - (numAdults !== '' ? numAdults : 1))}
                      onClick={() => this.incrementStateKey('numChildren')}
                      type="button"
                    >
                      <PlusSymbol color={buttonColor} width="2px" />
                    </button>
                    <input
                      id="numChildren"
                      max={maxGuests - (numAdults !== '' ? numAdults : 1)}
                      min="0"
                      onChange={this.createNumberHandler('numChildren')}
                      type="number"
                      value={numChildren}
                    />
                  </StyledFieldset>
                </label>
                <If condition={numGuests >= maxGuests}>
                  <MoreMessaging color={buttonColor}>
                    <FormattedHTMLMessage
                      id="booking.guestsMore"
                      values={{ maxGuests }}
                    />
                  </MoreMessaging>
                </If>
                <ButtonContainer>
                  <UnderlinedButton color={buttonColor} onClick={this.clearGuests} type="button"><FormattedMessage id="booking.inputClear" /></UnderlinedButton>
                  <UnderlinedButton color={buttonColor} onClick={this.applyParams} type="button"><FormattedMessage id="booking.inputApply" /></UnderlinedButton>
                </ButtonContainer>
              </StyledWidget>
            </If>
          </StyledControlsWrapper>
        </StyledWrapper>
        <StyledPromoCodeWrapper>
          <Choose>
            <When condition={!!this.props.promoCode && !promoCodeEditing && !searchInProgress}>
              <span>
                <Choose>
                  <When condition={!searchInitiated}>
                    <FormattedMessage id="booking.promoCodePremature" values={{ promoCode: this.props.promoCode }} />
                    &nbsp;<UnderlinedButton onClick={this.clearPromoCode} type="button"><FormattedMessage id="booking.promoCodePrematureRemove" /></UnderlinedButton>
                  </When>
                  <When condition={!promoCodeValid}>
                    ×&nbsp;<FormattedMessage id="booking.promoCodeInvalid" values={{ promoCode: this.props.promoCode }} />
                    &nbsp;<UnderlinedButton onClick={this.clearPromoCode} type="button"><FormattedMessage id="booking.promoCodeInvalidRemove" /></UnderlinedButton>
                  </When>
                  <Otherwise>
                    <svg fill="none" height="12" width="15" xmlns="http://www.w3.org/2000/svg"><path d="M1 6l4 5 9.5-10" stroke="#000" strokeWidth="1.25" /></svg>
                    &nbsp;<FormattedMessage id="booking.promoCodeValid" values={{ promoCode: this.props.promoCode }} />
                    &nbsp;<UnderlinedButton onClick={this.clearPromoCode} type="button"><FormattedMessage id="booking.promoCodeValidRemove" /></UnderlinedButton>
                  </Otherwise>
                </Choose>
              </span>
            </When>
            <When condition={promoCodeVisible}>
              <StyledForm onSubmit={this.applyPromoCode}>
                {/*
                  The following rule is disabled only because the linter is not
                  smart enough to recognise that `PromoCodeInput` is an input.
                  The rule is being followed.
                */}
                <label htmlFor="promoCode">{/* eslint-disable-line jsx-a11y/label-has-for */}
                  +&nbsp;<FormattedMessage defaultMessage="Promo Code" id="booking.promoCode" />
                  <PromoCodeInput
                    id="promoCode"
                    onChange={this.handlePromoCode}
                    type="text"
                    value={promoCode}
                  />
                </label>
                <PromoCodeSubmitButton disabled={!promoCode} edited={promoCode} type="submit">
                  <PlusSymbol color={promoCode ? buttonColor : COLOR_BLACK} width="2px" />
                </PromoCodeSubmitButton>
              </StyledForm>
            </When>
            <Otherwise>
              <PromoCodeButton onClick={this.showPromoCodeField} type="button">
                + <FormattedMessage defaultMessage="Promo Code" id="booking.promoCode" />
              </PromoCodeButton>
            </Otherwise>
          </Choose>
        </StyledPromoCodeWrapper>
      </Fragment>
    );
  }
}

BookingWidget.propTypes = {
  arrivalDate: PropTypes.string,
  blockedDays: PropTypes.array.isRequired,
  buttonColor: PropTypes.string,
  clearDates: PropTypes.func.isRequired,
  departureDate: PropTypes.string,
  intl: intlShape.isRequired,
  numAdults: PropTypes.number.isRequired,
  numChildren: PropTypes.number.isRequired,
  promoCode: PropTypes.string,
  promoCodeValid: PropTypes.bool.isRequired,
  searchInitiated: PropTypes.bool.isRequired,
  searchInProgress: PropTypes.bool.isRequired,
  searchParamsUpdated: PropTypes.func.isRequired,
  updateParams: PropTypes.func.isRequired,
  updatePromoCode: PropTypes.func.isRequired,
  windowSize: windowSizeTypes.isRequired,
};

BookingWidget.defaultProps = {
  buttonColor: COLOR_WHITE,
};

const mapStateToProps = (state) => {
  const {
    availability: { blockedDays, promoCodeValid, searchInitiated, searchInProgress },
    bookingWidget: { arrivalDate, departureDate, numAdults, numChildren, promoCode },
    ui: { windowSize },
  } = state;
  return {
    arrivalDate,
    blockedDays,
    departureDate,
    numAdults,
    numChildren,
    promoCode,
    promoCodeValid,
    searchInitiated,
    searchInProgress,
    windowSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearDates: () => dispatch({ type: BOOKING_WIDGET_CLEAR_DATES }),
  searchParamsUpdated: (payload) => dispatch({ type: SEARCH_PARAMS_UPDATED, payload }),
  updateParams: (payload) => dispatch({ type: BOOKING_WIDGET_UPDATE_PARAMS, payload }),
  updatePromoCode: (payload) => dispatch({ type: BOOKING_WIDGET_UPDATE_PROMO_CODE, payload }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BookingWidget));
