import styled from 'styled-components';


export default styled.button`
  padding: 0;

  border: 0;
  background: none;

  cursor: pointer;
`;
